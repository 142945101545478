header {
    padding-top: 50px;
    margin-bottom: 50px;
    @media only screen and (max-width: 1024px) {
        margin-bottom: 100px;
    }

    @media only screen and (min-width: 1364px) {
        margin-bottom: 125px;
    }
    .grid {
        justify-content: center;

        .text-header {
            position: relative;
            margin-bottom: 0;
            bottom: -50px;

            @media only screen and (min-width: 480px) {
                bottom: -75px;
            }

            @media only screen and (min-width: 640px) {
                bottom: -110px;
            }

            @media only screen and (min-width: 768px) {
                bottom: -60px;
            }

            @media only screen and (min-width: 1024px) {
                bottom: -80px;
            }

            @media only screen and (min-width: 1280px) {
                bottom: -115px;
            }
        }

        p {
            position: relative;
            top: 40px;
            font-size: clamp(20px, 3vw, 30px);
            line-height: 1.6em;
        }

        img {
            height: 400px;
            width: auto;
            position: relative;
            object-fit: contain;

            @media only screen and (min-width: 640px) {
                height: 700px;
            }
            @media only screen and (min-width: 1280px) {
                height: 837px;
                object-position: bottom;
            }
        }
    }
}

.img-box {
    @media only screen and (min-width: 1024px) {
        margin-bottom: 100px;
    }
    .big-img {
        @media only screen and (min-width: 1024px) {
            position: relative;
            right: 0;
            display: flex;
            justify-content: flex-end;
        }

        picture {
            @media only screen and (min-width: 1024px) {
                width: calc(75%);
            }

            img {
                @media only screen and (min-width: 1024px) {
                    width: 100%;
                }
            }
        }
    }

    .small-img {
        @media only screen and (max-width: 1024px) {
            display: none;
        }

        position: absolute;
        bottom: 0;
        left: 0;
        transform: translateY(100px);

        img {
            height: 250px;
            width: 250px;

            @media only screen and (min-width: 1364px) {
                height: 361px;
                width: 361px;
            }
        }
    }
}

.counter-sec {
    h2 {
        font-size: clamp(30px, 6vw, 50px);
        @media only screen and (min-width: 1280px) {
            margin-bottom: 50px;
        }
    }

    .counter-box {
        padding-top: 50px;
        padding-bottom: 50px;
        margin-bottom: 50px;
        .number {
            font-size: 96px;
            line-height: 1em;
            font-weight: 600;
            display: block;
            margin-bottom: 0;
        }

        .number-description {
            font-size: 25px;
            line-height: 1.4em;
        }
    }
}

.rating-section {
    background-repeat: no-repeat;
    background-position: center bottom -20px;

    @media only screen and (min-width: 1364px) {
        background-image: url("../assets/Images/rating.png");
    }
    .ratings {
        display: grid;
        grid-template-columns: repeat(12, 1fr);

        @media only screen and (min-width: 768px) {
            gap: 36px;
        }

        .rating-container {
            grid-column: span 12;
            padding: 36px;
            margin-bottom: 36px;
            position: relative;

            @media only screen and (min-width: 768px) {
                padding: 75px;
                grid-column: span 6;
            }

            @media only screen and (min-width: 1364px) {
                grid-column: span 4;
                bottom: -20px;
            }

            &.second {
                @media only screen and (min-width: 1364px) {
                    grid-column: 9 / 13;
                    bottom: 20px;
                }
            }

            .rating-header {
                display: flex;
                flex-wrap: wrap;
                gap: 18px;
                justify-content: space-between;

                margin-bottom: 36px;
                span {
                    font-size: 24px;
                }
            }
        }
    }
}

footer {
    position: relative;
    z-index: 50;
    overflow-x: hidden;

    @media only screen and (min-width: 1024px) {
        padding-top: 350px !important;
    }

    @media only screen and (min-width: 1280px) {
        padding-top: 420px !important;
    }

    @media only screen and (min-width: 1520px) {
        padding-top: 580px !important;
    }

    &::after {
        background-image: url("../assets/Images/lukifooter.png");
        position: absolute;
        left: -80px;
        bottom: 0;
        display: block;
        height: 800px;
        width: 400px;
        background-position: top right;
        background-size: cover;
        background-repeat: no-repeat;
        z-index: 500;

        @media only screen and (min-width: 1024px) {
            content: "";
        }

        @media only screen and (min-width: 1280px) {
            left: -20px;
            height: 900px;
            width: 500px;
        }

        @media only screen and (min-width: 1520px) {
            height: 1047px;
            width: 722px;
            left: 50%;
            transform: translateX(-59rem);
        }
    }

    #scroll-container {
        margin-bottom: 20px;
        min-height: fit-content;
        .scroll {
            animation: loop 60s linear infinite;

            .item {
                display: flex;
                align-items: center;
                gap: 0 0.2rem;
                font-size: 0.9rem;
                padding: 0.7rem 1rem;
                margin: 0rem 1rem;
                border-radius: 0.4rem;
                white-space: nowrap;
                font-size: 30px;

                @media only screen and (min-width: 1024px) {
                    font-size: 50px;
                }

                @media only screen and (min-width: 1520px) {
                    font-size: 75px;
                    height: 70px;
                }
            }
        }
        .fade {
            width: 100%;
            position: absolute;
            inset: 0;
            pointer-event: none;
        }
    }
}

@keyframes loop {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-50%);
    }
}

@import "tailwindcss/base";
@import "./base.css";

@import "tools/barba";
@import "tools/aos";
@import "tailwindcss/components";
@import "./components.css";
@import "./page.css";

@import "tailwindcss/utilities";
@import "./utilities.css";

#quickbar {
    position: fixed;
    height: fit-content;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    z-index: 9999;
    padding: 10px;
    transition: 0.4s linear;

    li {
        @apply bg-ui-medium;
        padding: 20px;
        border-radius: 999px;
    }

    label[for="navi-toggled"] {
        display: block;
        cursor: pointer;
        width: 25px;
        height: 25px;
        position: relative;
        overflow: hidden;

        span {
            transition: 0.4s linear;
            text-indent: -9999px;
            width: 0;
            top: 50%;
            transform: translate(-50%, -50%);

            &,
            &:before,
            &:after {
                content: "";
                position: absolute;
                display: block;
                @apply bg-ui-dark;
                height: 1px;
                left: 50%;
                transform-origin: center;
            }
            &:before,
            &:after {
                top: calc(50% + 0rem);
                width: 25px;
                transition: top 0.3s ease-in-out, transform 0.3s 0.2s linear;
            }
            &:before {
                transform: translate(-50%, -50%) rotate(45deg);
            }
            &:after {
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        }
    }
}

#navi-toggled {
    display: none;
    &:not(:checked) {
        ~ #navigation {
            opacity: 0;
            transform: translate(100%);
        }

        ~ #quickbar {
            @apply bg-transparent;
            label[for="navi-toggled"] {
                &:after {
                    opacity: 0;
                    visibility: hidden;
                }
                span {
                    width: 25px;

                    &:before,
                    &:after {
                        transform: translate(-50%, -50%);
                        transition: top 0.3s 0.2s ease-in-out,
                            transform 0.3s linear;
                    }
                    &:before {
                        top: -10px;
                    }
                    &:after {
                        top: 10px;
                    }
                }
            }
        }
    }
}

#navigation {
    transition: 0.5s all;
    position: fixed;
    right: 0;
    opacity: 1;
    padding-top: 85px;
    top: 0;
    z-index: 9998;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    transform: translate(0);
    @apply bg-ui-medium;

    @media only screen and (min-width: 992px) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .navi-container {
        @media only screen and (min-width: 992px) {
            display: flex;
            align-items: flex-end;
            transform: translateY(-55px);
        }

        @media only screen and (min-width: 1520px) {
            transform: translateX(-100px) translateY(-55px);
        }
    }

    nav {
        padding: 18px;

        @media only screen and (min-width: 992px) {
            padding-right: 25px;
            border-right: 1px solid;
            @apply border-ui-dark;
        }

        @media only screen and (min-width: 1364px) {
            padding-right: 50px;
        }

        ul {
            li {
                display: block;
                margin-bottom: 18px;
                cursor: pointer;
                @media only screen and (min-width: 992px) {
                    text-align: right;
                }
                a {
                    font-size: 26px;
                    line-height: 1.3em;
                    background-size: 0% 100%;

                    &:hover {
                        background-size: 100% 100%;
                    }

                    @media only screen and (min-width: 480px) {
                        font-size: 36px;
                    }

                    @media only screen and (min-width: 640px) {
                        font-size: 46px;
                    }

                    @media only screen and (min-width: 992px) {
                        font-size: 40px;
                    }

                    @media only screen and (min-width: 1200px) {
                        font-size: 56px;
                    }

                    @media only screen and (min-width: 1364px) {
                        font-size: 64px;
                    }

                    @media only screen and (min-width: 1620px) {
                        font-size: 80px;
                    }
                }
            }
        }
    }

    .social-stuff {
        padding-top: 18px;
        margin-left: 18px;
        margin-right: 18px;
        border-top: 1px solid;
        @apply border-ui-dark;

        @media only screen and (min-width: 992px) {
            padding-top: 0;
            margin: 0;
            border-top: unset;
            padding-left: 25px;
            padding-bottom: 36px;
        }

        @media only screen and (min-width: 1364px) {
            padding-left: 50px;
        }

        .maillink {
            margin-bottom: 18px;
            display: inline-block;
            @media only screen and (min-width: 480px) {
                font-size: 24px;
            }
        }
        .icons {
            display: flex;
            flex-direction: row;
            gap: 18px;

            > a {
                svg {
                    transition: 0.3s all;
                }
                &:hover {
                    svg {
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
}

.navi-add {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    @media only screen and (max-width: 1024px) {
        margin-bottom: rem(80px);
    }

    @media only screen and (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
    }

    @media only screen and (min-width: 1024px) {
        width: rem(774px);
    }

    li {
        transition: 0.3s all;
        @media only screen and (max-width: 768px) {
            &:not(:last-child) {
                border-bottom: 1px solid;
                @apply border-ui-dark/50;
            }
        }

        &:hover {
            @apply border-ui-primary;
            a {
                @apply text-ui-light bg-ui-primary;

                @media only screen and (min-width: 768px) {
                    @apply text-ui-primary bg-ui-medium;
                }
            }
        }

        a {
            @apply text-ui-dark;
            padding: 20px;
            display: block;
            text-decoration: none;
            transition: 0.3s all;

            @media only screen and (min-width: 768px) {
                padding: 40px 18px;

                &:first-child() {
                    padding-left: 0;
                }
                &:last-child() {
                    padding-right: 0;
                }
            }

            @media only screen and (min-width: 1024px) {
                padding: 40px 20px;
            }
        }
    }
}

/* ul.transition-barba {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 9999999;
    height: 100%;
    width: 100vw;
    top: 0;
    left: 0;
    margin: 0;
    pointer-events: none;

    li {
        transform: scaleX(0);
        background-color: red;
        height: 20%;
    }
} */

.transition-barba-full {
    position: fixed;
    z-index: 9999999;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    @apply bg-ui-dark;
    pointer-events: none;
    transform: scaleY(0);
}
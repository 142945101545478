.no-scroll-bar {
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }
}


.back-to-top {
    @apply bg-ui-dark text-ui-light;
	transition: 0.3s all;
	position: fixed;
	bottom: 1rem;
	right: 1rem;
	z-index: 800;
	font-size: 1rem;
	width: 3em;
	height: 3em;
	line-height: 3em;
	text-decoration: none;
	text-align: center;
	transform: translateY(0);
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
        content: url("../assets/icons/arrow-up.svg");
        height: 24px;
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        top: 6px;
    }
	
	body:not(.scrolling-up) & {
		opacity: 0;
		visibility: hidden;
		transform: translateY(3em);
	}

	&:hover, &:active, &:focus {
		@apply text-ui-light bg-ui-dark/80;
	}

	span {
		display: none;
	}
}
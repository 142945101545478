.sec-pad {
    padding: 50px 0;

    @media only screen and (min-width: 992px) {
        padding: 100px 0;
    }

    @media only screen and (min-width: 1024px) {
        padding: 100px 0;
    }

    @media only screen and (min-width: 1364px) {
        padding: 125px 0;
    }

    &.no-top {
        padding-top: 0 !important;
    }

    &.no-bottom {
        padding-bottom: 0 !important;
    }
}

.sec-marg {
    margin: 50px 0;

    @media only screen and (min-width: 1024px) {
        margin: 100px 0;
    }

    @media only screen and (min-width: 1364px) {
        margin: 125px 0;
    }

    &.no-top,
    &.no-top-marg {
        margin-top: 0 !important;
    }

    &.no-bottom {
        margin-bottom: 0 !important;
    }
}

.btn {
    @apply bg-ui-dark text-white;
    border: 1px solid;
    width: fit-content;
    padding: 12px 36px;
    gap: 10px;
    transition: all 0.3s;
    display: block;
    margin-bottom: 1.5rem;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        @apply bg-ui-primary;
        @apply text-ui-light;
    }

    &.fill {
        @apply bg-ui-primary text-ui-light;

        &:hover {
            @apply bg-transparent text-ui-primary;
        }
    }
}

/* FAQ */
details {
    summary {
        cursor: pointer;
        background-image: none;
        -webkit-appearance: none;
        transition: 300ms all;

        &::after {
            content: url("../assets/icons/x.svg");
            transform: rotate(45deg);
            position: relative;
            transition: 300ms all;
        }
    }

    &[open] {
        summary {
            &::after {
                transform: rotate(0);
            }
        }
    }
}

details summary::-webkit-details-marker {
    display: none;
}

a.download-list {
    &:not(.aktu-box) {
        position: relative;
        transition: padding-left 0.3s ease;
        &::after {
            content: url("../assets/icons/download.svg");
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        &:hover {
            padding-left: 20px;
        }
    }
}

.download-icons {
    a {
        @apply bg-ui-primary;
        transition: 300ms all;
        border-radius: 40px;
        padding: 8px;
        height: 35px;
        width: 35px;
        display: flex;
        justify-content: center;

        &:hover {
            @apply bg-ui-dark;
        }
    }
}

.video-container {
    margin-bottom: 29px;

    &:not(.video-tag) {
        position: relative;
        height: 0;
        overflow: hidden;
        /* 16/9 aspect ratio */
        padding-bottom: 56.25%;
        position: relative;

        iframe {
            border: none;
            height: 100%;
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
        }
    }

    &.wide {
        /* 21/9 aspect ratio */
        padding-bottom: 42.85%;
    }

    video {
        width: 100%;
        height: auto;
    }
}

.fullImage {
    a {
        display: block;
        height: 100%;
    }
    figure {
        picture {
            img {
                max-height: 400px;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }

    @media only screen and (min-width: 768px) {
        figure {
            height: 100%;
            overflow: hidden;
            position: relative;

            picture {
                img {
                    margin-bottom: 0;
                    width: 100%;
                    max-width: inherit;
                    max-height: inherit;
                    position: absolute;
                }
            }
        }
    }
}

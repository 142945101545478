* {
	margin: 0;
	padding: 0;

	&,
	&:before,
	&:after {
		box-sizing: inherit;
	}
}

html {
    font-family: "Poppins";
    font-weight: 200;
    @apply bg-ui-light;
    @apply text-ui-dark;
	box-sizing: border-box;
	font-size: 16px;
    line-height: 1.6em;
	hyphens: manual;
    scroll-behavior: auto;

	/* disable text resize in landscape. e.g. on iphone */
	text-size-adjust: none;
    
    ::-webkit-scrollbar {
        width: 10px;
    }
    
    ::-webkit-scrollbar-track {
        @apply bg-ui-medium;
    }
    
    ::-webkit-scrollbar-thumb {
        @apply bg-ui-secondary;
    }
    
    ::-webkit-scrollbar-thumb:hover {
        @apply bg-ui-primary;
    }
    
    ::-webkit-calendar-picker-indicator {
        filter: invert(1);
    }
    
    html.lenis, html.lenis body {
        height: auto;
    }
    
    .lenis.lenis-smooth {
        scroll-behavior: auto !important;
    }
    
    .lenis.lenis-smooth [data-lenis-prevent] {
        overscroll-behavior: contain;
    }
    
    .lenis.lenis-stopped {
        overflow: hidden;
    }
    
    .lenis.lenis-scrolling iframe {
        pointer-events: none;
    }
}

body {
    line-height: 1.6em;
    opacity: 1;
}

iframe,
[data-iframe] {
	border: none;
	width: 100%;
}

address {
	font-style:normal;
}


/* Headline Styles */
h1,
h2,
h3,
h4,
h5,
h6 {
    @apply text-ui-dark;
    display: block;
    text-rendering: optimizeLegibility;
    hyphens: auto;
    font-family: "Poppins";
    margin-bottom: 29px;

    span {
        display: block;
        line-height: normal;
        font-weight: 200;
        font-size: clamp(20px, 2vw, 44px);
    }
}

h1, .h1 {
    @apply text-h1;
}

h2, .h2 {
    @apply text-h2;
}

h3, .h3 {
    @apply text-h3;
}

h4, .h4 {
    @apply text-h4;
}

h5, .h5 {
    @apply text-h5;
}

h6, .h6 {
    @apply text-h6;
}

p {
    margin-bottom: 29px;
}

/* Link Syles */
a {
    text-decoration: underline;
    transition: 350ms all;
    word-wrap: break-word;
	text-decoration: none;
	background-size: 100% 100%;
    
    &:not(.btn, .branding, .no-underline) {
        background-image: linear-gradient(transparent calc(100% - 1px), currentColor 1px);
        background-repeat: no-repeat;
        background-position: left;
        &:hover,
        &:focus {
            background-size: 0% 100%;
        }
    }
}

a[data-fancybox="gallery"] {
    position: relative;
    display: block;
    &::after {
        content: "+";
        display: block;
        position: absolute;
        color: #7d7d7d;
        transition: 0.3s all;
        text-align: center;
        line-height: 1.8;
        font-size: 1.5rem;
        height: 1.5em;
        width: 1.5em;
        bottom: 0;
        right: 0;
        z-index: 1;
        transform: translate3d(0, 0, 0);
    }

    &:hover {
        &:after {
            transform: translate3d(50%, 50%, 0);
            font-size: 2.5rem;
            line-height: 1.5;
            height: 1.5em;
            width: 1.5em;
            bottom: 50%;
            right: 50%;
        }
    }
}

a:not(.btn):focus,
[tabindex]:focus {
	@apply outline-ui-primary;
	outline-offset: 5px;
}


/* List Styles */
ul,
ol {
    &.styled-list {
        li {
            @apply border-b border-ui-medium;
            padding-left: 30px;
            padding-bottom: 1rem;
            margin-bottom: 1rem;
            position: relative;

            &:last-child {
                border-bottom: none;
            }
            
    
            &:before {
                @apply fill-ui-dark;
                top: .125rem;
                left: 0;
                position: absolute;
                content: url("../assets/icons/check.svg");
                
            }

            ul {
                margin-left: 1rem;
                margin-bottom: 0;
            }
        }
    }
}

hr,
.hr {
    @apply bg-ui-dark/40;
	display: flex;
	border: none;
	clear: both;
	height: 1px;
	margin: 10px 0;
	flex-basis: 100%;
}

/* Image Styless */
img {
	vertical-align: bottom;
	float: none;
	height: auto;
	max-width: 100%;
	width: 100%;
}


/**
 * Tables
 */
 main {
	table {
        @apply max-sm:!overflow-x-auto max-sm:!block;
		display: table;
		border-collapse: collapse;
		width: 100%;
		max-width: 100%;
		text-align: left;
		font-size: 1rem;

		caption {
			text-align: left;
			padding: 1rem 1rem 25px;
			display: table-caption;
			font-weight: bold;
			font-size: 1.2rem;
		}

		thead {
			border-collapse: collapse;
			display: table-header-group;
		}

		tbody {
			width: 100%;
			overflow-x: scroll;
			display: table-row-group;

			tr {
				padding: 1rem 0;

				&:not(:last-child) {
					border-bottom: 1px solid;
                    @apply border-ui-medium;
				}
			}
		}

		tr {
			display: table-row;
		}

		th,
		td {
			padding: 1rem;
			display: table-cell;
			vertical-align: top;
		}

        td {
            @apply max-sm:min-w-[50vw]
        }
	}
}